<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="mr-25 mb-0">
        <a href="#" @click="getdata()">
          Refresh
          <b-badge variant="light-primary">
            {{ total }}
          </b-badge>
        </a>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
  },
  data() {
    return {
      total: "৳0",
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "0/0",
          subtitle: "Total/Monthly Transaction",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "0/0",
          subtitle: "Total/Monthly DTM Registration",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "AwardIcon",
          color: "light-success",
          title: "৳0",
          subtitle: "This Month`s Collection",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-danger",
          title: "৳0",
          subtitle: "Panding Amount",
          customClass: "",
        },
      ],
    };
  },
  methods: {
    getdata() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      this.$http
        .get(`/dtm/reg/pay/userDash/${userdata.username}/`)
        .then((response) => {
          var data = response.data.account;
          this.statisticsItems[0].title =
            data.total_tran + "/" + data.this_month_tran;
          this.statisticsItems[1].title =
            data.total_reg + "/" + data.this_month_reg;
          this.statisticsItems[2].title = `৳` + data.this_month_amount;
          this.statisticsItems[3].title = `৳` + data.panding_amount;
          this.total = `৳` + data.total_amount;
        })
        .catch((error) => console.log(error));
    },
  },
  created() {
    this.getdata();
  },
};
</script>
